import React from 'react'
import SectionHeader from "./SectionHeader";

import img1 from '../static/img/witraz1.png';
import img2 from '../static/img/witraz8.png';
import img3 from '../static/img/witraz7.png';
import SingleTechnology from "./SingleTechnology";

const TechnologiesSection = () => {
    return <section className="section section--technologies" id="technologie">
        <SectionHeader
            title="Dowiedz się więcej"
            left={false} />

        <main className="technologies">
            <img data-aos="fade-right" className="absolute img--technologies1" src={img1} alt="technologie-tworzenia-witrazy" />
            <img data-aos="fade-left" className="absolute img--technologies2" src={img2} alt="technologie-tworzenia-witrazy" />
            <img data-aos="fade-right" className="absolute img--technologies3" src={img3} alt="technologie-tworzenia-witrazy" />

            <h3 data-aos="fade-down" className="aboutUs__header aboutUs__header--technologies">
                More about us
            </h3>

            <SingleTechnology
                number="01"
                name="Our work"
                desc="We invite you to see our stained glass windows"
                extraClass="technology--1"
                link="/witraze" />
            <SingleTechnology
                number="02"
                name="Art gallery"
                desc="We invite you to our Art Gallery in Rypin"
                extraClass="technology--2"
                link="/galeria-sztuki" />
            <SingleTechnology
                number="03"
                name="Contact"
                desc="If you need stained glass on request - please do not hesitate to contact us"
                extraClass="technology--3"
                link="/kontakt" />
        </main>
    </section>
}

export default TechnologiesSection;
