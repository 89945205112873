import React, { useState, useEffect } from 'react'
import PageHeader from "./PageHeader";

import witraz1 from '../static/img/Anioł1.jpg'
import witraz2 from '../static/img/Anioł2.jpg'
import witraz3 from '../static/img/anioł_rogowo_pdf.jpg'
import witraz4 from '../static/img/Anioły_-_Mauzoleum_Kindlerów_w_Pabianicach.jpg'
import witraz5 from '../static/img/Bóg_Ojciec_w_kościele_pw._św._Mateusza_w_Pabianicach.jpg'

import witraz6 from '../static/img/ksiezyc.png'
import witraz7 from '../static/img/zegar.png'
import witraz8 from '../static/img/aniol-w-owalu.png'

const WitrazeSubpageMain = ({type}) => {
    const sakralne = [
        {
            img: witraz5,
            title: "God the Father in the church of st. Mateusz in Pabianice",
        },
        {
            img: witraz3,
            title: "Angel in the Church in Rogowo",
        },
        {
            img: witraz4,
            title: "Angels in the Kindler Mausoleum in Pabianice",
        }
    ]

    const kameralne = [
        {
            img: witraz6,
            title: "The moon",
        },
        {
            img: witraz7,
            title: "Clock",
        },
        {
            img: witraz8,
            title: "Angel in the oval - blue",
        }
    ]

    const [witraze, setWitraze] = useState([]);

    useEffect(() => {
        if(type === "sakralne") {
            setWitraze(sakralne);
        }
        else {
            setWitraze(kameralne);
        }
    }, [type]);

    return <main className="pageContent">
        <PageHeader title={type === "sakralne" ? "Church stained glass" : "Small stained glass"} />
        <main className="pageContent__witrazeGrid">
            {witraze.map((item, index) => {
                return <section data-aos="fade-in" className="pageContent__witrazeGrid__item" key={index}>
                    <img className="pageContent__witraze__img" src={item.img} alt={item.title} />
                    <main className="pageContent__witraze__section__content">
                        <h3 className="pageContent__witraze__section__header">
                            {item.title}
                        </h3>
                    </main>
                </section>
            })}
        </main>
    </main>
}

export default WitrazeSubpageMain;
