import React from 'react'
import logo1 from '../static/img/logo-1.png';
import logo2 from '../static/img/logo-2.png';
import logo3 from '../static/img/logo-3.png';
import logo4 from '../static/img/logo-4.jpg';
const Footer = () => {
    return <footer className="section footer">
        <div className="ue">
            <img className="img" src={logo1} alt="logo" />
            <img className="img" src={logo2} alt="logo" />
            <img className="img" src={logo3} alt="logo" />
            <img className="img" src={logo4} alt="logo" />
        </div>

        <section className="footer__section">
            <h4 className="footerHeader footerHeader--navigation">
                Navigation
            </h4>
            <ul className="footer__list footer__list--flex">
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/">
                        Homepage
                    </a>
                </li>
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/referencje">
                        References
                    </a>
                </li>
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/galeria-sztuki">
                        Art gallery
                    </a>
                </li>
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/konserwacja">
                        Conservation
                    </a>
                </li>
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/o-nas">
                        About us
                    </a>
                </li>
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/kontakt">
                        Contact
                    </a>
                </li>
            </ul>
        </section>
        <section className="footer__section">
            <h4 className="footerHeader footerHeader--links">
                Links
            </h4>
            <ul className="footer__list">
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/polityka-prywatnosci">
                        Privacy policy
                    </a>
                </li>
                <li className="footer__list__item">
                    <a className="footer__list__item__link" href="/regulamin">
                        Terms of service
                    </a>
                </li>
            </ul>
        </section>
        {/*<section className="footer__section">*/}
        {/*    <h4 className="footerHeader footerHeader--shop">*/}
        {/*        Online store*/}
        {/*    </h4>*/}
        {/*    <ul className="footer__list">*/}
        {/*        <li className="footer__list__item">*/}
        {/*            <a className="footer__list__item__link" href="/sklep/witraze-kameralne">*/}
        {/*                Small stained glass windows*/}
        {/*            </a>*/}
        {/*        </li>*/}
        {/*        <li className="footer__list__item">*/}
        {/*            <a className="footer__list__item__link" href="/sklep/anioly">*/}
        {/*                Angels*/}
        {/*            </a>*/}
        {/*        </li>*/}
        {/*        <li className="footer__list__item">*/}
        {/*            <a className="footer__list__item__link" href="/sklep/zegary">*/}
        {/*                Clocks*/}
        {/*            </a>*/}
        {/*        </li>*/}
        {/*        <li className="footer__list__item">*/}
        {/*            <a className="footer__list__item__link" href="/sklep/lampy">*/}
        {/*                Lamps*/}
        {/*            </a>*/}
        {/*        </li>*/}
        {/*    </ul>*/}
        {/*</section>*/}

        <aside className="footer__bottom">
            <h5 className="footer__bottom__header">
                &copy; { new Date().getFullYear() } Pracownia Witraży Bednarscy.
            </h5>
            <h5 className="footer__bottom__header">
                Created by: <a className="footer__bottom__header__link" href="https://skylo.pl" target="_blank" rel="noreferrer">skylo.pl</a>
            </h5>
        </aside>
    </footer>
}

export default Footer;
