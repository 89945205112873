import React from 'react'
import PageHeader from "./PageHeader";

import bigImage from '../static/img/witraz1.png'
import img1 from '../static/img/witraz2.png'
import img2 from '../static/img/witraz3.png'
import img3 from '../static/img/witraz4.png'

const ArtGalleryMain = () => {
    return <main className="pageContent">
        <PageHeader title="Galeria sztuki" />
        <main className="pageContent__artGallery">
            <img data-aos="fade-right" className="pageContent__artGallery__bigImage" src={bigImage} alt="galeria-sztuki-bednarscy" />

            <article className="pageContent__artGallery__desc">
                <p data-aos="fade-left">
                    We will be pleased to welcome you to our Art Gallery. The gallery, which is being built at
                    Rypinska Pracownia Witraży, was a response to the tender taste of our supporters: people who
                    they are looking for extraordinary items or people who want a timeless beauty
                    enclosed in our classic stained glass windows, it was the pride of their homes.
                </p>
                <p data-aos="fade-left">
                    We invite you to the Gallery, because we believe that communing with beauty is very important. This
                    We want to emphasize this belief especially nowadays. Art sensitizes, teaches,
                    helps to know the truth about ourselves. It awakens the curiosity of the world, he wonders. Sometimes it is outrageous and
                    makes you reflect ... But it always gives us something.
                </p>
                <p data-aos="fade-left">
                    In our gallery you will find a cross-section of various works. We offer consistently beautiful
                    small and gift stained glass, clocks and lamps made using the traditional Tiffany technique.
                    We also exhibit contemporary paintings, sculptures and artistic handicrafts. we work with
                    many appreciated artists of applied art, whose works are in our offer.
                </p>
                <p data-aos="fade-left">
                    We also work with our guests: we make stained glass according to their ideas, on
                    special occasions. We personalize gifts, help choose the right dedications and
                    we appreciate such work with you very much.
                </p>

                        <section className="pageContent__artGallery__images" data-aos="fade-up">
                    <img className="pageContent__artGallery__images__img" src={img1} alt="galeria-sztuki-bednarscy" />
                    <img className="pageContent__artGallery__images__img" src={img2} alt="galeria-sztuki-bednarscy" />
                    <img className="pageContent__artGallery__images__img" src={img3} alt="galeria-sztuki-bednarscy" />
                </section>
            </article>
        </main>
    </main>
}

export default ArtGalleryMain;
