import React from 'react'
import SectionHeader from "./SectionHeader";

import img1 from '../static/img/witraz6.png'
import img2 from '../static/img/witraz5.png'
import Button from "./Button";

const AboutUsSection = () => {
    return <section className="section section--aboutUs">
        <SectionHeader
            title="About us"
            left={true} />

        <main className="aboutUs">
            <img data-aos="fade-right" className="absolute aboutUs__img--1" src={img1} alt="witraze-sakralne-bednarscy" />
            <img data-aos="fade-left" className="absolute aboutUs__img--2" src={img2} alt="witraze-sakralne-bednarscy" />

            <article className="aboutUs__content" data-aos="zoom-in-down">
                <h3 className="aboutUs__header">
                    Ladies and Gentlemen,
                </h3>

                <p className="aboutUs__text">
                    The need for communing with art is in all of us. Often stifled by the sheer volume of work,
                    piling up obligations may seem to be a superfluous value. You can't fool yourself with this one
                    sensitivity: it is impossible to cover your eyes when something delights them, and tears when they are moved
                    beauty.
                </p>

                <Button
                    text="More"
                    extraClass="button--aboutUs"
                    link="/o-nas" />
            </article>
        </main>
    </section>
}

export default AboutUsSection;
