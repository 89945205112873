import React from 'react'

const BackHome = () => {
    return <aside className="shop__top d-desktop">
        <a className="shop__top__link" href="/">
            Back home - witraze-bednarscy.pl
        </a>
    </aside>
}

export default BackHome;
