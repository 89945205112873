import React from 'react'
import PageHeader from "./PageHeader";

import img1 from '../static/img/witraz6.png';
import img2 from '../static/img/hero3.jpg';
import img3 from '../static/img/witraz5.png';
import Button from "./Button";

const AboutUsMain = () => {
    return <main className="pageContent">
        <PageHeader title="O nas" />
        <section className="aboutUs__section">
            <img data-aos="fade-right" className="aboutUs__section__img aboutUs__section__img--1" src={img1} alt="witraze-bednarscy" />

            <article data-aos="fade-left" className="aboutUs__section__content">
                <h3 className="aboutUs__section__header">
                    Stained Glass Bednarscy
                </h3>
                <p>
                    The Stained Glass Workshop was founded in 1972 and is currently run by visual artists: Elżbieta Bednarska and Bartosz Bednarski - son.
                </p>
                <p>
                    In almost 50 years, we have made hundreds of monumental stained glass windows in over 200 buildings
                    sacred and secular. Among them, we can boast of design and implementation
                    the largest stained glass window in Europe, with an area of over 200 m2, located in
                    the church of Saint Stanisław Kostka in Rypin. In his body of work
                    We have several dozen individual exhibitions and a permanent exhibition in the Gallery
                    Of Art, operating at the Stained Glass Workshop. We make stained glass according to the traditional,
                    classical stained glass art, while small chamber works, including lamps under
                    customer orders are created in the Tiffany technique. We also do maintenance and
                    restoring historic stained glass windows, restoring their original splendor and beauty.
                </p>
                    <p>
                        As part of many years of activity and experience, we offer you comprehensive services,
                        consisting in the design of unique, individual patterns, their implementation and
                        installation. We invite you.
                    </p>
                        <section className="aboutUs__section__buttons">
                    <Button text="Realizacje witraży"
                            link="/witraze"
                            extraClass="button--aboutUs" />
                    <Button text="Galeria sztuki"
                            link="/galeria-sztuki"
                            extraClass="button--aboutUs" />
                </section>
            </article>
        </section>

        {/*<section className="aboutUs__section aboutUs__section--end">*/}
        {/*    <article data-aos="fade-right" className="aboutUs__section__content aboutUs__section__content--2">*/}
        {/*        <h3 className="aboutUs__section__header">*/}
        {/*            Nasza historia - jak to się wszystko zaczęło?*/}
        {/*        </h3>*/}
        {/*        <p>*/}
        {/*            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est*/}
        {/*        </p>*/}
        {/*        <p>*/}
        {/*            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est*/}
        {/*        </p>*/}
        {/*        <p>*/}
        {/*            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est*/}
        {/*        </p>*/}
        {/*    </article>*/}

        {/*    <img data-aos="fade-left" className="aboutUs__section__img aboutUs__section__img--2" src={img2} alt="witraze-bednarscy" />*/}
        {/*</section>*/}

        {/*<section className="aboutUs__section aboutUs__section--center">*/}
        {/*    <img data-aos="fade-right" className="aboutUs__section__img aboutUs__section__img--3" src={img3} alt="witraze-bednarscy" />*/}

        {/*    <article data-aos="fade-left" className="aboutUs__section__content aboutUs__section__content--3">*/}
        {/*        <h3 className="aboutUs__section__header">*/}
        {/*            Kolejny nagłówek*/}
        {/*        </h3>*/}
        {/*        <p>*/}
        {/*            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est*/}
        {/*        </p>*/}
        {/*        <p>*/}
        {/*            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est*/}
        {/*        </p>*/}
        {/*        <p>*/}
        {/*            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est*/}
        {/*        </p>*/}
        {/*    </article>*/}
        {/*</section>*/}
    </main>
}

export default AboutUsMain;
