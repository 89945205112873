import React from 'react'
import PageHeader from "./PageHeader";

import witrazeSakralne from '../static/img/witraz2.png'
import witrazeKameralne from '../static/img/witraz3.png'

const WitrazeMain = () => {
    return <main className="pageContent">
        <PageHeader title="Witraże" />

        <main className="pageContent__witraze">
            <section data-aos="fade-in" className="pageContent__witraze__section">
                <img className="pageContent__witraze__img" src={witrazeSakralne} alt="witraze-sakralne" />
                <main className="pageContent__witraze__section__content">
                    <h3 className="pageContent__witraze__section__header">
                        Church stained glass
                    </h3>
                    <p className="pageContent__witraze__section__desc">
                        In the link below you can find some of the sacred stained glass windows we have created.
                    </p>
                    <a className="button button--witraze" href="/witraze/witraze-sakralne">
                        Show more
                    </a>
                </main>
            </section>

            <section data-aos="fade-in" className="pageContent__witraze__section">
                <img className="pageContent__witraze__img" src={witrazeKameralne} alt="witraze-kameralne" />
                <main className="pageContent__witraze__section__content">
                    <h3 className="pageContent__witraze__section__header">
                        Small stained glass
                    </h3>
                    <p className="pageContent__witraze__section__desc">
                        In the link below you can find some of the intimate stained glass windows we have created.
                    </p>
                    <a className="button button--witraze" href="/witraze/witraze-kameralne">
                        Show more
                    </a>
                </main>
            </section>
        </main>
    </main>
}

export default WitrazeMain;
